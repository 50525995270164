exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-index-js": () => import("./../../../src/pages/all/index.js" /* webpackChunkName: "component---src-pages-all-index-js" */),
  "component---src-pages-candusen-index-js": () => import("./../../../src/pages/candusen/index.js" /* webpackChunkName: "component---src-pages-candusen-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-paper-page-js": () => import("./../../../src/templates/paper-page.js" /* webpackChunkName: "component---src-templates-paper-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

